import React, { useReducer, useState } from 'react'

import NavigationHeader from '../../components/organisms/NavigationHeader'
import NavigationFooter, {
  filterReducer,
  initialEvacuationFilter,
  initialPartnerCompanySelection,
  initialUserSafetyFilter,
} from '../../components/organisms/NavigationFooter'
import NavigationSidebar from '../../components/organisms/NavigationSidebar'
import { Map } from './Map'
import UserSafetyListTable from './UserSafetyListTable'
import { FetchUserSafetyListRes } from '../../data/types/DisasterRiskManagement'
import type { Map as MapLibreMap } from 'maplibre-gl'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import NewSpinner from '../../components/common/NewSpinner'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { useCurrentRoute } from 'react-navi'
import { publicRoutesConstant } from '../../appConstants'

type Bounds = {
  top: number
  left: number
  right: number
  bottom: number
}

const NewDashboard = () => {
  const [
    shouldSafetyListTableDisplay,
    setShouldSafetyListTableDisplay,
  ] = useState(false)
  const [preventionItemsToggle, setPreventionItemsToggle] = useState(false)
  const [isTableMinimize, setIsTableMinimize] = useState(true)
  const [tableData, setTableData] = useState<FetchUserSafetyListRes>([])
  const [mapObject, setMapObject] = useState<null | MapLibreMap>(null)
  const [
    preventionItemsToggleDisabled,
    setPreventionItemsToggleDisabled,
  ] = useState(true)

  const disasterStatusFetching = useIsFetching({
    queryKey: ['disaster-status'],
    exact: false,
  })
  const registerItemsFetching = useIsFetching({
    queryKey: ['disaster-register-items'],
    exact: false,
    stale: false, // do not show loader when invalidating
  })
  const disasterItemsFetching = useIsFetching({
    queryKey: ['disaster-items'],
    exact: false,
  })
  const userSafetyListFetching = useIsFetching({
    queryKey: ['user-safety-list'],
    exact: false,
  })

  const mapCoordinatesFetching = useIsFetching({
    queryKey: ['map'],
    exact: false,
  })

  const isMutating = useIsMutating({
    predicate(mutation) {
      return !mutation.options.mutationKey?.includes(
        'mutation-disaster-register-items'
      )
    },
  })

  const pathname = useCurrentRoute().url.pathname
  const publicFacingURL = publicRoutesConstant.includes(pathname)

  const parentRef = React.useRef<HTMLDivElement>(null)
  const tableRef = React.useRef<HTMLDivElement>(null)
  const headerRef = React.useRef<HTMLElement>(null)
  const sidebarRef = React.useRef<HTMLDivElement>(null)
  const footerRef = React.useRef<HTMLDivElement>(null)
  const [defaultPos, setDefaultPos] = useState({
    x: 0,
    y: -(window.innerWidth > 1800 ? 193.5 : 125),
  })
  const [bounds, setBounds] = useState({})
  const [position, setPosition] = useState({ ...defaultPos })

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    devicePixelRatio: window.devicePixelRatio,
  })

  React.useEffect(() => {
    const handleResizeOrZoom = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        devicePixelRatio: window.devicePixelRatio, // Detect zoom level
      })
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResizeOrZoom)

    // Optionally, you can also use setInterval to detect zoom continuously
    const intervalId = setInterval(() => {
      handleResizeOrZoom()
    }, 500) // Check for zoom every 500ms

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleResizeOrZoom)
      clearInterval(intervalId) // Clear interval when component unmounts
    }
  }, [])

  const [filterState, updateFilterState] = useReducer(filterReducer, {
    partnerListSelection: initialPartnerCompanySelection,
    evacuationListSelection: initialEvacuationFilter,
    safetyListSelection: initialUserSafetyFilter,
  })

  // Update position during drag
  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    setPosition({ x: data.x, y: data.y })
  }

  // Set the table to default position to display the table properly maximized
  const setTableToDefaultPosition = () => {
    setPosition({ ...defaultPos })
  }

  const getBounds = () => {
    if (
      parentRef?.current &&
      tableRef?.current &&
      headerRef?.current &&
      sidebarRef?.current &&
      footerRef?.current
    ) {
      const {
        height: parentHeight,
      } = parentRef.current?.getBoundingClientRect()

      const {
        width: tableWidth,
        height: tableHeight,
      } = tableRef.current?.getBoundingClientRect()

      const {
        height: headerHeight,
      } = headerRef.current?.getBoundingClientRect()

      const {
        width: sidebarWidth,
      } = sidebarRef.current?.getBoundingClientRect()

      const {
        height: footerHeight,
      } = footerRef.current?.getBoundingClientRect()

      const minTop =
        parentHeight / 2 -
        tableHeight -
        headerHeight - 1;
      const minLeft = sidebarWidth + tableWidth - window.innerWidth + 20
      const maxBottom =
        tableHeight - footerHeight - (window.innerHeight < 900 ? 68 : 86)

      const calculatedBounds = {
        top: minTop,
        left: minLeft,
        right: 0,
        bottom: maxBottom,
      }
      return calculatedBounds
    }
    return {} as Bounds
  }

  React.useEffect(() => {
    if (isTableMinimize) {
      setDefaultPos({ x: 0, y: getBounds()?.top })
      setBounds({ ...getBounds() })
    }
  }, [shouldSafetyListTableDisplay, isTableMinimize, windowSize])

  return (
    <>
      {disasterStatusFetching > 0 ||
      registerItemsFetching > 0 ||
      disasterItemsFetching > 0 ||
      userSafetyListFetching > 0 ||
      mapCoordinatesFetching > 0 ||
      isMutating > 0 ? (
        <NewSpinner />
      ) : null}
      {/* Navbar/Header */}
      {!publicFacingURL ? (
        <NavigationHeader
          headerRef={headerRef}
          mapObject={mapObject}
          preventionItemsToggle={preventionItemsToggle}
          setPreventionItemsToggle={setPreventionItemsToggle}
          preventionItemsToggleDisabled={preventionItemsToggleDisabled}
          setPreventionItemsToggleDisabled={setPreventionItemsToggleDisabled}
        />
      ) : null}
      {/* Sidebar */}
      <NavigationSidebar sidebarRef={sidebarRef} mapObject={mapObject} />

      {/* Map */}
      <div className="uk-flex uk-map uk-position-relative">
        <Map
          tableData={tableData}
          setTableData={setTableData}
          mapObject={mapObject}
          setMapObject={setMapObject}
        />
        {/* Safety List Table */}
        <div className="table-parent" ref={parentRef}
          // on maximizing, set higher z-index so that table comes over footer
          // in case some part would be hidden (below footer)
          style={{ zIndex: isTableMinimize ? undefined : '10000' }}
        >
          <Draggable
            disabled={!isTableMinimize} // Disable dragging when maximized
            defaultPosition={defaultPos}
            position={position}
            bounds={bounds}
            onDrag={handleDrag}
          >
            <div>
              <UserSafetyListTable
                tableRef={tableRef}
                shouldSafetyListTableDisplay={shouldSafetyListTableDisplay}
                setShouldSafetyListTableDisplay={
                  setShouldSafetyListTableDisplay
                }
                isTableMinimize={isTableMinimize}
                setIsTableMinimize={setIsTableMinimize}
                filterState={filterState}
                setTableToDefaultPosition={setTableToDefaultPosition}
              />
            </div>
          </Draggable>
        </div>
      </div>

      {/* Footer */}
      <NavigationFooter
        footerRef={footerRef}
        mapObject={mapObject}
        setShouldSafetyListTableDisplay={setShouldSafetyListTableDisplay}
        shouldSafetyListTableDisplay={shouldSafetyListTableDisplay}
        preventionItemsToggle={preventionItemsToggle}
        setPreventionItemsToggle={setPreventionItemsToggle}
        preventionItemsToggleDisabled={preventionItemsToggleDisabled}
        setPreventionItemsToggleDisabled={setPreventionItemsToggleDisabled}
        filterState={filterState}
        updateFilterState={updateFilterState}
      />
    </>
  )
}

export default NewDashboard
